// --> ELIMINAR DESPUES
// @use "../../styles/variables" as *;

// .creditNote-page {
//   display: flex;
//   flex-direction: column;
//   gap: 10px;
//   &__row {
//     display: flex;
//     flex-direction: row;
//     gap: 10px;
//   }
//   &__search {
//     width: 70%;
//   }
//   &__date-picker {
//     flex-wrap: unset;
//   }
//   &__message-no-results {
//     padding-top: 50px;
//   }
//   select {
//     min-height: 40px;
//     font-size: 16px;
//     padding-left: 0.5rem;
//     text-transform: capitalize;
//     border: 0.1rem solid $primary;
//     border-radius: 0.3rem !important;
//   }
// }

// .datePicker-creditnote {
//   min-height: 40px;
//   font-size: 16px;
//   padding-left: 0.5rem;
//   text-transform: capitalize;
//   border: 0.1rem solid $primary;
//   border-radius: 0.3rem !important;
// }

// @media screen and (min-width: 769px) and (max-width: 1100px) {
//   main.open .creditNote-page .grid {
//     grid-template-columns: repeat(1, 1fr);
//   }
// }
.items-notes {
  display: flex;
  gap: 4px; /* Consistent spacing between all children */
  align-items: center;
}
.search-icon {
  width: 24px; /* Adjust to match icon size */
  height: 24px; /* Adjust to match icon size */
  left: 0%;
  display: block; /* Prevents extra space below the icon */
}
